import config from '@/config'
import { ServerEvent } from '@/enums'
import useBasicApi from '@/api/useBasicApi'

const axios = config.urls.AXIOS_API

export default function powerApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/power_controllers`

  return {
    ENDPOINT,
    UPDATE_EVENT: ServerEvent.NODE_POWER_CONTROL_MODE_UPDATED,
    ...useBasicApi(ENDPOINT, ['get']),
    for: deviceId => ({
      ...useBasicApi(`${ENDPOINT}/${deviceId}`, ['get']),
      set: mode => axios.put(`${ENDPOINT}/${deviceId}/`, { mode }),
    }),
  }
}
